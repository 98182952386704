<template>
    <OModal ref="createNewActivityDialog" >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Create New Activity") }}
                        </h4>
                        <div v-if="isExecuting" class="spinner-border spinner-border-sm" style="0.35rem;" role="status">
                            <span class="visually-hidden">{{ $t('Processing...') }}</span>
                        </div>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">
                    <div class="row pl-3">
                        <div class="col-6">
                            <label>{{$t("Org.unit")}}: *</label>        
                            <OrgunitsLookup 
                                textInput
                                :bind="sel=>{OrgUnit_ID = sel.ID; OrgUnit = sel.Name; OrgUnitIdPath = sel.IdPath; AccessIdPath = sel.AccessIdPath}"
                                class="form-control form-control-sm "
                                v-model="OrgUnit" />
                        </div>
                        <div class="col-6">
                            <label>{{$t("Activity Type")}}: *</label>        
                            <ActivityTypesLookup 
                                textInput
                                :textInputValue="ActivityType"
                                :IdPath="OrgUnitIdPath"
                                :bind="sel=>{ActivityType_ID = sel.ID; ActivityType = sel.Name; ActivityGroupID = sel.ActivityGroup_ID}"
                                class="form-control form-control-sm " />
                        </div>

                        <div class="col-6">
                            <label>{{$t("Name")}}: *</label>   
                            <input type="text" class="form-control " v-model="Name">
                        </div>
                       
                        <div class="col-6">
                            <label>{{$t("Checklist")}}:</label>        
                            <ChecklistLookup 
                                textInput :IdPath="OrgUnitIdPath"
                                :textInputValue="Checklist" :ActivityGroupID="ActivityGroupID"
                                :bind="sel=>{
                                    Checklist_ID = sel.ID; 
                                    Checklist = sel.Name;
                                    Revision = sel.Revision; 
                                    CLProcedure = sel.Procedure; 
                                    DisciplineID = sel.Discipline_ID;
                                    CompletionPhaseID = sel.CompletionPhase_ID;
                                    FrequencyNumberOfDays = sel.FrequencyNumberOfDays;
                                }"
                                class="form-control form-control-sm " />
                        </div>

                        <div class="col-6">
                            <label>{{$t("Handover Type")}}:</label>    
                            <HandoverTypesLookup 
                                :IdPath="OrgUnitIdPath" 
                                :bind="sel=>{
                                        HandoverType_ID = sel.ID; HandoverType = sel.Name;
                                        Checklist=sel.Checklist;
                                        Checklist_ID=sel.Checklist_ID;
                                        Revision=sel.Revision;
                                        CompletionPhaseID=sel.CompletionPhase_ID;
                                        ActivityType_ID=sel.ActivityType_ID; 
                                        ActivityType = sel.ActivityType}" textInput :textInputValue="HandoverType"
                                        class="form-control form-control-sm ">
                            </HandoverTypesLookup> 
                        </div>

                        <div class="col-6"> 
                            <label>{{ $t("Sub Project") }}</label>            
                            <SubProjectsLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{SubProject_ID = sel.ID; SubProject = sel.Name;}"
                                class="form-control form-control-sm " 
                                :textInputValue="SubProject" />
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Construction Phase") }}</label>
                            <ConstructionPhasesLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{ConstructionPhase_ID = sel.ID; ConstructionPhase = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="ConstructionPhase" />
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Shutdown") }}</label>
                            <ShutdownsLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{Shutdown_ID = sel.ID; Shutdown = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Shutdown" /> 
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Milestone") }}</label>                                                               
                            <MilestonesLookup 
                                textInput
                                :IdPath="OrgUnitIdPath"
                                :bind="sel=>{Milestone_ID = sel.ID; Milestone = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Milestone" /> 
                        </div>
                        <div class="col-6">
                            <label>{{ $t("Package") }}</label>
                            <PackageLookup 
                                textInput
                                :OrgUnitId="OrgUnit_ID"
                                :bind="sel=>{ Package_ID = sel.ID; Package = sel.Name;}"
                                class="form-control form-control-sm "
                                :textInputValue="Package" /> 
                        </div>
                         
                    </div>
                </div>
                            
                <div class="modal-footer">       
                    <div style="display:inline" class="float-start">
                        <label>{{ $t("Number of Copies:") }} </label>
                        <input type="number" v-model="numberOfCopies" class="form-control" min="1" max="100" style="max-width:80px;display:inline">
                    </div>

                    <button class="btn btn-outline-primary" :disabled="isExecuting || !OrgUnit_ID || !ActivityType_ID || !Name" @click="createActivity">{{$t("Save and Close")}}</button>    
                    <button class="btn btn-outline-primary" :disabled="isExecuting || numberOfCopies !== 1 || !OrgUnit_ID || !ActivityType_ID || !Name" @click="createActivityAndOpen">{{$t("Save and Open Activity")}}</button>                    
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>

import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
import { ref, defineExpose, computed, watch } from 'vue';
import OModal from 'o365.vue.components.Modal.vue'; 
import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue'
import SubProjectsLookup from "complete.vue.components.SubProjectsLookup.vue";
import ConstructionPhasesLookup from "complete.vue.components.ConstructionPhasesLookup.vue";
import ShutdownsLookup from "complete.vue.components.ShutdownsLookup.vue";
import MilestonesLookup from "complete.vue.components.MilestonesLookup.vue";
import PackageLookup from "complete.vue.components.PackageLookup.vue";
import ActivityTypesLookup from "complete.vue.components.ActivityTypesLookup.vue";
import ChecklistLookup from "check.vue.components.ChecklistLookup.vue";
import HandoverTypesLookup from "complete.vue.components.HandoverTypesLookup.vue";
import context from "o365.modules.Context.ts";

const OrgUnit_ID = ref();
const OrgUnit = ref();
const OrgUnitIdPath = ref();
const AccessIdPath = ref();
const ActivityType_ID = ref();
const ActivityType = ref();
const Name = ref();
const Checklist = ref();
const Checklist_ID = ref();
const HandoverType = ref();
const HandoverType_ID = ref();
const SubProject = ref();
const SubProject_ID = ref();
const Shutdown_ID = ref();
const Shutdown = ref();
const ConstructionPhase_ID = ref();
const ConstructionPhase = ref();
const Milestone_ID = ref();
const Milestone = ref();
const Package_ID = ref();
const Package = ref(); 
const ActivityGroupID = ref();
const numberOfCopies = ref(1)

const DisciplineID = ref();
const CompletionPhaseID = ref();
const FrequencyNumberOfDays = ref();
const CLProcedure = ref();
const Revision = ref();

const do_actsToBeCreated = getOrCreateDataObject({
    id: 'do_actsToBeCreated' + crypto.randomUUID(),
    viewName: 'atbv_Complete_Activities',
    uniqueTable: 'atbv_Complete_Activities',
    distinctRows: false,
    maxRecords: 100,
    allowInsert: true,
    allowUpdate: true,
    allowDelete: true,
    fields: [
        { name: "PrimKey", type: "string" },
        { name: "ID", type: "Number" },
        { name: "Name", type: "string" },
        { name: "OrgUnit_ID", type: "Number" },
        { name: "ActivityType_ID", type: "Number" },
        { name: "Checklist_ID", type: "Number" },
        { name: "Milestone_ID", type: "Number" },
        { name: "Shutdown_ID", type: "Number" },
        { name: "ProjectPhase_ID", type: "Number" },
        { name: "ConstructionPhase_ID", type: "Number" },
        { name: "MainArea_ID", type: "Number" },
        { name: "Discipline_ID", type: "Number" },
        { name: "SubProject_ID", type: "Number" },
        { name: "Package_ID", type: "Number" },
        { name: "HandoverType_ID", type: "Number" },
        { name: "CompletionPhase_ID", type: "Number" },
        { name: "FrequencyNumberOfDays", type: "Number" },
        { name: "Procedure", type: "string" },
        { name: "Revision", type: "Number" }
    ]
});

const createNewActivityDialog = ref();
const isExecuting = ref(false);
const procDuplicateActs = getOrCreateProcedure({ id: "procDuplicateActs" + crypto.randomUUID(), procedureName: "astp_Complete_DuplicateActivity" });
 
defineExpose({ show });

async function show() {
    OrgUnit_ID.value = context.id;
    OrgUnit.value = context.orgUnit;
    OrgUnitIdPath.value = context.idPath;
    AccessIdPath.value = context.idPath;
    ActivityType_ID.value = null;
    ActivityType.value = null;
    Name.value = null;
    Checklist.value = null;
    Checklist_ID.value = null;
    HandoverType.value = null;
    HandoverType_ID.value = null;
    SubProject.value = null;
    SubProject_ID.value = null;
    Shutdown_ID.value = null;
    Shutdown.value = null;
    ConstructionPhase_ID.value = null;
    ConstructionPhase.value = null;
    Milestone_ID.value = null;
    Milestone.value = null;
    Package_ID.value = null;
    Package.value = null;
    DisciplineID.value = null;
    CompletionPhaseID.value = null;
    FrequencyNumberOfDays.value = null;
    CLProcedure.value = null;
    Revision.value = null;
    createNewActivityDialog.value.show();
}

const props = defineProps({
    DataObjectToReload: {
        type: Object,
        required: false
    },
    Parent_ID: {
        type: Number,
        required: false
    }
});

async function saveRecord() {
    do_actsToBeCreated.createNew({
        OrgUnit_ID: OrgUnit_ID.value,
        OrgUnitIdPath: OrgUnitIdPath.value,
        AccessIdPath: AccessIdPath.value,
        Name: Name.value,
        ActivityType_ID: ActivityType_ID.value,
        Checklist_ID: Checklist_ID.value,
        Milestone_ID: Milestone_ID.value,
        Shutdown_ID: Shutdown_ID.value,
        ConstructionPhase_ID: ConstructionPhase_ID.value,
        Package_ID: Package_ID.value,
        Parent_ID: props.Parent_ID,
        CompletionPhase_ID: CompletionPhaseID.value,
        Discipline_ID: DisciplineID.value,
        FrequencyNumberOfDays: FrequencyNumberOfDays.value,
        Procedure: CLProcedure.value,
        Revision: Revision.value,
        HandoverType_ID: HandoverType_ID.value,
        SubProject_ID: SubProject_ID.value
    }); 
    await do_actsToBeCreated.save();
}

async function createActivityAndOpen() {
    isExecuting.value = true;
    saveRecord().then( () => {
        if (numberOfCopies.value === 1) {
            createNewActivityDialog.value.hide();
            isExecuting.value = false;
            window.location.href = "/activity?ID=" + do_actsToBeCreated.current.ID;
        }
        else if (numberOfCopies.value > 1) {
            procDuplicateActs.execute({
                Activity_ID: do_actsToBeCreated.current.ID,
                NumberOfCopies: +numberOfCopies.value-1
            }).then( () => {
                props.DataObjectToReload?.load();
                createNewActivityDialog.value.hide();
                isExecuting.value = false;    
            });
        }             
    })     
}

async function createActivity() {
    isExecuting.value = true;
    saveRecord().then( () => {
        if (numberOfCopies.value === 1) {
            props.DataObjectToReload?.load();
            createNewActivityDialog.value.hide();
            isExecuting.value = false;       
        }
        else if (numberOfCopies.value > 1) {
            procDuplicateActs.execute({
                Activity_ID: do_actsToBeCreated.current.ID,
                NumberOfCopies: +numberOfCopies.value-1
            }).then( () => {
                props.DataObjectToReload?.load();
                createNewActivityDialog.value.hide();
                isExecuting.value = false;    
            });
        }             
    })       
}

</script>